import { LocalMessagePurpose } from '@norges-domstoler/dds-components';

interface MyInterface {
    types: Record<number, LocalMessagePurpose>;
}

export const messageTypes: MyInterface = {
    types: {
        10: 'info',
        20: 'warning',
    },
};
