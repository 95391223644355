import { domstolno, tilsynsutvalget } from '../../Models/Constants/SiteTypes';

export enum SiteType {
    Domstolno = 10,
    Tilsynsutvalget = 20,
}

export const getSiteTypeName = (value: SiteType): string => {
    switch (value) {
        case SiteType.Domstolno:
            return domstolno;
        case SiteType.Tilsynsutvalget:
            return tilsynsutvalget;
        default:
            return '';
    }
};
